import { useRouteError } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

export default function ErrorPage() {
  const error = useRouteError() as any;
  console.error(error);

  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="lg"
        id="error-page"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: '100vh'
        }}
      >
        <h1>Oh no!</h1>
        <p>An unexpected error has occurred.</p>
        <p>
            <b>Error:</b> <i>{error.status}</i> <i>{error.statusText}</i>
        </p>
      </Container>
    </>
  );
}
